import React, { useState } from 'react';
import axios from 'axios';

function ContactArea() {
    // State to hold form data
    const [formData, setFormData] = useState({
        subject: '',
        email: '',
        message: ''
    });

    // Update form state
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if all fields are filled
        if (!formData.subject || !formData.email || !formData.message) {
            alert('Please fill in all fields.');
            return; // Stop the function if any field is empty
        }

        const endpoint = 'https://api.maduxxgames.com/web/contact/send';

        try {
            const response = await axios.post(endpoint, formData);
            if (response.status === 202) { // Check if the response status is 202
                console.log('Message sent:', response.data);
                alert('Message sent successfully!');
                // Reset form data if the response is successful
                setFormData({
                    subject: '',
                    email: '',
                    message: ''
                });
            } else {
                // Handle any other response that is not 202
                console.log('Received non-202 response:', response.data);
                alert('Message received, but with unexpected response status.');
            }
        } catch (error) {
            console.error('Failed to send message:', error);
            alert('Failed to send message.');
        }
    }

    return (
        <section className="contact-area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pl-45">
                        <div className="section-title title-style-three mb-20">
                            <h2>CONTACT US</h2>
                        </div>
                        <div className="contact-info-list mb-40">
                            <ul>
                                <li><i className="fas fa-map-marker-alt"/>8555 Jane Street, Suite 302, Vaughan, ON L4K 5N9</li>
                                <li><i className="fas fa-envelope"/>info@maduxx.com</li>
                            </ul>
                        </div>
                        <div className="contact-form">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            placeholder="Subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            placeholder="Your Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <textarea
                                    name="message"
                                    id="message"
                                    placeholder="Write Message..."
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                                <button type="submit">SUBMIT MESSAGE</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactArea;
