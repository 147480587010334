import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import HeaderTwo from '../../../components/header/HeaderTwo';
import Breadcrumb from './Breadcrumb';
import Footer from '../../../components/footer/Footer';
import ScrollToTopRoute from "../../../components/scrolltotop/ScrollToTopRoute";

import BalloonBashPage from './games/BalloonBashPage';
import FrenzyPage from "./games/FrenzyPage";
import MetalJacketPage from "./games/MetalJacketPage";
import FallingBlocksPage from "./games/FallingBlocksPage";
import RealityRunnerPage from "./games/RealityRunnerPage";


function GameSingle() {
    return (
        <>
            <HeaderTwo/>
            <main>
                <Breadcrumb />

                <ScrollToTopRoute exact={true} path='/balloon-bash'>
                    <BalloonBashPage/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/52-frenzy'>
                    <FrenzyPage/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/metal-jacket'>
                    <MetalJacketPage/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/falling-blocks'>
                    <FallingBlocksPage/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/reality-runner'>
                    <RealityRunnerPage/>
                </ScrollToTopRoute>

            </main>
            <Footer/>
        </>
    )
}

export default GameSingle