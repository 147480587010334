import React from 'react'

function FallingBlocksPage() {
    return (
        <section className="game-single-area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="game-single-content">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <h4>FALLING BLOCKS</h4>
                                </div>
                            </div>
                            <p>Embark on a vibrant journey with 'Balloon Bash', where every click leads to a burst of
                                joy! This visually stunning game, crafted in Unity, brings a kaleidoscope of animated
                                balloons to life. As you tap or click, watch as balloons pop in an explosion of colors,
                                each offering unique points and mesmerizing effects. But it's not just about popping
                                balloons - 'Balloon Bash' elevates the experience with advanced balloon physics, making
                                every interaction feel incredibly real.</p>
                            <p>Dive into a game where intelligence meets fun - our AI-driven mechanics ensure a unique
                                experience every time, adapting to your style for an endlessly engaging play. With a
                                serene backdrop that soothes the eyes and intricate coding that guarantees seamless
                                gameplay, 'Balloon Bash' is perfect for players of all ages looking to add a splash of
                                color to their day.</p>
                            <div className="game-single-img">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <img src="assets/img/images/game_single_img01.jpg" alt=""/>
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="assets/img/images/game_single_img02.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <p>Optimized for web, desktop, and mobile devices, you can take your balloon-bashing skills
                                anywhere. And when you achieve that high score? Share it with friends and family through
                                our integrated social media sharing feature. Burst your way to the top in 'Balloon Bash'
                                - where every pop is a step into a more colorful world!</p>
                            <div className="game-single-title mt-60 mb-30">
                                <h4>Specifications</h4>
                            </div>
                            <div className="game-single-info mb-65">
                                <ul>
                                    <li><span>Category:</span>Casual</li>
                                    <li><span>Platforms:</span>PC, iOS, Android</li>
                                </ul>
                            </div>
                            <div className="game-single-gallery">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery01.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery02.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery03.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery04.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery05.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/img/images/game_single_gallery06.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="game-single-shape"><img src="assets/img/images/game_section_shape.png"
                                                                    alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FallingBlocksPage