import React from 'react';
import Newsletter from './Newsletter'

function Footer() {
    return (
        <footer>
            <div className="footer-top footer-bg">
                {/* newsletter-area */}
                <Newsletter/>
                {/* newsletter-area-end */}
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footer-widget mb-50">
                                <div className="footer-logo mb-35">
                                    <a href="/"><img src="assets/img/logo/logo.png" alt=""/></a>
                                </div>
                                <div className="footer-text">
                                    <div className="footer-contact">
                                        <ul>
                                            <li>
                                                <i className="fas fa-map-marker-alt"/> <span>Address: </span>
                                                8555 Jane St, Suite 302
                                                <br/>
                                                Vaughan, ON L4K 5N9
                                            </li>
                                            <li><i className="fas fa-headphones"/> <span>Phone: </span>+1 905.660.3000
                                            </li>
                                            <li><i className="fas fa-envelope-open"/><span>Email: </span>info@maduxx.com
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Need Help?</h5>
                                </div>
                                <div className="fw-link">
                                    <ul>
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        {/*<li><a href="/#">FAQ</a></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Follow us</h5>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/Maduxx.Inc/" target="_blank"><i
                                            className="fab fa-facebook-f"/></a></li>
                                        <li><a href="https://www.twitter.com/maduxx_inc" target="_blank"><i
                                            className="fab fa-x-twitter"/></a></li>
                                        <li><a href="https://www.instagram.com/maduxx_inc" target="_blank"><i
                                            className="fab fa-instagram"/></a></li>
                                        <li><a href="https://www.tiktok.com/@maduxxinc" target="_blank"><i
                                            className="fab fa-tiktok"/></a></li>
                                        <li><a href="https://www.linkedin.com/company/maduxx" target="_blank"><i
                                            className="fab fa-linkedin-in"/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-fire"><img src="assets/img/images/footer_fire.png" alt=""/></div>
                <div className="footer-fire footer-fire-right"><img src="assets/img/images/footer_fire.png" alt=""/>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="copyright-text">
                                <p>Copyright © 2024 <a href="https://maduxx.com/" target="_blank">MADUXX</a> All Rights
                                    Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
