import React from 'react';
import axios from 'axios';

function Newsletter() {
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const email = event.target.elements.email.value.trim(); // Access the email directly from the form input and trim any whitespace

        // Check if the email input is empty or whitespace
        if (!email) {
            alert('Please enter a valid email address.');
            return; // Stop the function if the check fails
        }

        try {
            const response = await axios.put('https://api.maduxxgames.com/web/newsletter/add', { email });
            console.log('Newsletter subscription successful:', response.data);
            alert('Thank you for subscribing!');
            event.target.reset(); // Reset the form after successful submission
        } catch (error) {
            console.error('Error subscribing to newsletter:', error);
            alert('Failed to subscribe. Please try again later.');
        }
    };

    return (
        <div className="newsletter-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="newsletter-wrap">
                            <div className="section-title newsletter-title">
                                <h2>Our <span>Newsletter</span></h2>
                            </div>
                            <div className="newsletter-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="newsletter-form-grp">
                                        <i className="far fa-envelope"/>
                                        <input
                                            type="email"
                                            name="email" // Important: Set the name attribute for direct access
                                            placeholder="Enter your email..."
                                        />
                                    </div>
                                    <button type="submit">SUBSCRIBE <i className="fas fa-paper-plane"/></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;
