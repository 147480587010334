import React from 'react';
import {Link, useLocation} from 'react-router-dom';

function Breadcrumb() {
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter(x => x);
    const lastPath = pathNames[pathNames.length - 1].replace(/-/g, ' ');
    const words = lastPath.split(' ');
    const lastWord = words.pop();
    const initialWords = words.join(' ');

    return (
        <section className="breadcrumb-area breadcrumb-bg s-breadcrumb-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content text-center">
                            <h2>
                                {initialWords}
                                {lastWord && <span> {lastWord}</span>}
                                {!lastWord && 'Home'}
                            </h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/">Games</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{lastPath || 'Home'}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Breadcrumb;
