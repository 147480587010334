import React from 'react'

function TeamMem() {
  return (
	 <section className="team-member-area pt-115 pb-125">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2>Our team <span>members</span></h2>
                <p>Meet the members behind our team!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 01 Johnny PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Johnny Maalouf</a></h4>
                  <span>CEO/Creative Director</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 02 Romey PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Romey Christo</a></h4>
                  <span>Project Coordinator</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 03 Jeff PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Jeffrey Lu</a></h4>
                  <span>Lead Programmer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 04 Dario PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Dario Cavalarri</a></h4>
                  <span>Lead Game Artist</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 05 Junaed PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Junaed Bari</a></h4>
                  <span>Junior Programmer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="assets/img/team/Team 06 Brandon PIC 01.jpg" alt=""/>
                </div>
                <div className="team-member-content">
                  <h4><a>Brandon Maalouf</a></h4>
                  <span>Junior Game Artist</span>
                </div>
              </div>
            </div>
          </div>
        </div>
     </section>
  )
}

export default TeamMem