import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';

function NewGamesArea() {

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)
    let slider1, slider2;


    useEffect(function () {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])


    return (

        <section className="released-games-area gray-bg pt-115 pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-title title-style-three text-center mb-20">
                            <h2>OUR <span>GAMES</span></h2>
                            <p>Explore our collection of mini and casual games, each crafted with care, creativity, and
                                a touch of Maduxx magic. From puzzles to adventures, each game is an invitation to a new
                                world.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-8 col-lg-12">
                        <Slider className="released-game-active"
                                asNavFor={nav2}
                                ref={slider => (slider1 = slider)}
                                arrows={false}
                        >
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img01.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>Balloon <span>Bash</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category: </span>Casual</li>
                                                <li><span>Platform: </span>PC, iOS, Android</li>
                                            </ul>
                                        </div>
                                        <p>Dive into a game where intelligence meets fun - our AI-driven mechanics
                                            ensure a unique experience every time, adapting to your style for an
                                            endlessly engaging play.</p>
                                        <a href="/balloonbash" target="_blank" className="btn btn-style-two">Play Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img02.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>52 <span>Frenzy</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category:</span>Casual</li>
                                                <li><span>Controller:</span>Web</li>
                                            </ul>
                                        </div>
                                        <p>Exhilarating card game tests speed, reflexes, and strategy. Click scattered
                                            cards, clear the deck, compete globally. Addictive challenge for all, with
                                            quick reflexes and strategic card selection determining high scores on the
                                            worldwide leaderboard.</p>
                                        <a href="/52frenzy" target="_blank" className="btn btn-style-two">play now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img03.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>Reality <span>Runner</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category:</span>Casual Runner</li>
                                                <li><span>Controller:</span>TBA</li>
                                            </ul>
                                        </div>
                                        <p>Relive childhood daydreams with an AR mobile game where
                                            your character sprints through real-world environments, leaping obstacles as
                                            you explore your surroundings.</p>
                                        <div className="btn btn-style-two">coming soon</div>
                                    </div>
                                </div>
                            </div>
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img04.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>Metal <span>Jacket</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category: </span>Casual</li>
                                                <li><span>Controller: </span>TBA</li>
                                            </ul>
                                        </div>
                                        <p>Blast targets in diverse environments to rack up high scores! Unlock and
                                            upgrade an arsenal of weapons like slingshots, bows, and guns. Compete on
                                            global leaderboards as you progress through dynamic levels with changing
                                            weather, obstacles, and more. Addictive, skill-based shooting action.</p>
                                        <div className="btn btn-style-two">coming soon</div>
                                    </div>
                                </div>
                            </div>
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img05.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>Bonk-a-<span>Bot</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category: </span>Casual</li>
                                                <li><span>Controller: </span>TBA</li>
                                            </ul>
                                        </div>
                                        <p>Addictive arcade adventure tests reflexes with unpredictable bots,
                                            satisfying 'bonks', scoring system, and global competition. Charming
                                            graphics, playful characters, and heart-pounding action for all ages.</p>
                                        <div className="btn btn-style-two">coming soon</div>
                                    </div>
                                </div>
                            </div>
                            <div className="released-game-carousel">
                                <div className="released-game-item">
                                    <div className="released-game-img">
                                        <img src="assets/released_game_img06.jpg" alt=""/>
                                    </div>
                                    <div className="released-game-content">
                                        <h4>Falling <span>Blocks</span></h4>
                                        <div className="released-game-list mb-15">
                                            <ul>
                                                <li><span>Category: </span>Casual</li>
                                                <li><span>Controller: </span>TBA</li>
                                            </ul>
                                        </div>
                                        <p> Captivating geometric puzzle game tests strategy and agility. Descending
                                            shapes, line clearing, minimalist art, sophisticated mechanics. Timeless
                                            classic for all puzzle fans.</p>
                                        <div className="btn btn-style-two">coming soon</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <Slider className="released-game-nav"
                                asNavFor={nav1}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                ref={slider => (slider2 = slider)} slidesToShow={3} vertical={true} arrows={false}>
                            <div className="released-game-nav-item">
                                <img src="assets/img/images/release_game_nav01.jpg" alt=""/>
                            </div>
                            <div className="released-game-nav-item">
                                <img src="assets/img/images/release_game_nav02.jpg" alt=""/>
                            </div>
                            <div className="released-game-nav-item">
                                <img src="assets/release_game_nav03.jpg" alt=""/>
                            </div>
                            <div className="released-game-nav-item">
                                <img src="assets/release_game_nav04.jpg" alt=""/>
                            </div>
                            <div className="released-game-nav-item">
                                <img src="assets/release_game_nav05.jpg" alt=""/>
                            </div>
                            <div className="released-game-nav-item">
                                <img src="assets/release_game_nav06.jpg" alt=""/>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewGamesArea