import React from 'react'

function UpcomingGames() {
    return (
        <section className="upcoming-games-area upcoming-games-bg pt-120 pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="upcoming-game-item mb-40">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <span>COMING SOON</span>
                                    <h4><a href="/#">Falling Blocks</a></h4>
                                </div>
                            </div>
                            <p>
                                Embark on a geometric journey with Falling Blocks, a captivating puzzle game that
                                combines strategy and agility in a symphony of shapes! In this enthralling challenge,
                                players stack distinctly colored geometric shapes within a grid play area, aiming to
                                create and clear complete horizontal lines. Each block descends with an air of
                                anticipation, as players strategically maneuver them into place, racing against gravity.
                            </p>
                            <div className="upcoming-game-thumb">
                                <img src="assets/games/upcoming/falling_blocks.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="upcoming-game-item mb-40">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <span>COMING SOON</span>
                                    <h4><a href="/#">Metal Jacket</a></h4>
                                </div>
                            </div>
                            <p>Metal Jacket offers an exhilarating shooting experience where players aim for high scores
                                by blasting targets across diverse environments. Start with simple slingshots and
                                progress to powerful guns, facing changing weather and challenging obstacles that test
                                your precision and adaptability. Each level introduces new dynamics, keeping the
                                gameplay fresh and engaging. Compete with players around the world on the leaderboard
                                and showcase your sharpshooting skills in this addictive, skill-based action game.</p>
                            <div className="upcoming-game-thumb">
                                <img src="assets/games/upcoming/metal_jacket.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="upcoming-game-item mb-40">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <span>COMING SOON</span>
                                    <h4><a href="/#">Reality Runner</a></h4>
                                </div>
                            </div>
                            <p>Experience your childhood daydreams anew with "Reality Runner," a cutting-edge mobile AR
                                game. Recall those imaginative moments during long car rides where you envisioned a
                                character sprinting and dodging obstacles beside you. Using your smartphone, "Reality
                                Runner" transforms this fond fantasy into reality, projecting dynamic characters that
                                race and hurdle right in your surroundings. Rediscover the magic of your youthful
                                adventures with every open space turning into your personal arcade.</p>
                            <div className="upcoming-game-thumb">
                                <img src="assets/games/upcoming/reality_runner.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="upcoming-game-item mb-40">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <span>COMING SOON</span>
                                    <h4><a href="/#">Bonk-a-Bot</a></h4>
                                </div>
                            </div>
                            <p>Step into the exhilarating world of Bonk-a-Bot, an addictive arcade adventure that tests
                                your reflexes like never before! In this high-energy game, animated bots pop up
                                unpredictably from their burrows, and with a realistic hammer cursor or tap effect, each
                                hit delivers a satisfying 'bonk'. It's a delightful race against time as the ticking
                                timer challenges you to beat your best score, while the engaging scoring system invites
                                friendly competition with players around the globe. Perfect for all ages, Bonk-a-Bot
                                combines charming graphics, playful characters, and heart-pounding action, making it an
                                irresistible download for anyone seeking thrilling, fast-paced fun.</p>
                            <div className="upcoming-game-thumb">
                                <img src="assets/games/upcoming/bonk_a_bot.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpcomingGames