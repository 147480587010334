function PrivacyInner() {
    return (
        <div className="container pt-100 pb-125">
            <div className="row align-items-center">
                <div className="section-title title-style-three mb-25">
                    <h2>Privacy <span>Policy</span></h2>
                </div>
                <div className="privacy-content">
                    <div class="maduxx-container privacy-policy">
                        <p> Maduxx Inc. is committed to protecting your right to privacy. This privacy policy informs
                            you of our practices relating to the collection, use, modification, disclosure and
                            destruction of personal information and tells you about the ways we ensure that your privacy
                            and the confidentiality of your information are protected.</p>
                        <p><strong>What does "personal information" mean?</strong> For the purposes of this Statement,
                            "personal information" means information about identifiable individuals and includes contact
                            information (for example, name, mailing address, e-mail address, telephone number),
                            demographic information, household information, preference information and
                            transaction-related information. Personal information does not include business information,
                            such as an individual’s business address and telephone number.</p>
                        <p><strong>Collecting personal information.</strong> Maduxx Inc. collects personal information
                            from individuals on a voluntary basis through surveys, contest entry forms, registration
                            forms, questionnaires and transaction documents.</p>
                        <p> Using personal information. Maduxx Inc. uses personal information that it collects for the
                            following limited purposes with respect to its business:
                        </p>
                        <ul>
                            <li>To communicate with individuals, corporations and other entities</li>
                            <li>To assist in understanding individuals’ needs and preferences and to adjust our products
                                and services accordingly
                            </li>
                            <li>To conduct, process and complete transactions with customers</li>
                            <li>Maduxx Inc. may, from time to time, contact individuals by electronic format, regular
                                mail or telephone for any of the purposes set out above.
                            </li>
                        </ul>
                        <br/>
                        <p><strong>Disclosing personal information.</strong> We knowingly disclose personal information
                            when we have received your consent to do so or under limited circumstances specifically
                            described when personal information is collected. However, we will disclose personal
                            information without such consent when such disclosure is permitted or required by law.
                        </p>
                        <p> We will not knowingly disclose personal information to any third parties except:
                        </p>
                        <p> To companies related to or carrying on business as Maduxx Inc. and only for the limited
                            purposes described above. These related companies and their respective employees are
                            restricted from using the personal information for any other purpose other than as described
                            above.
                        </p>
                        <p> To its employees, professional advisors, promotional and marketing agencies, and to any
                            other third parties in order to acquire customers, complete transactions, to process data
                            and to service our customers. These third parties and their respective employees are only
                            provided with such personal information as is necessary to perform the services for which
                            they have been contracted and are restricted from using the personal information for any
                            purpose other than as described above. Maduxx Inc. does not sell, rent or trade personal
                            information that it collects. In the event of the sale of Maduxx Inc. or one or more of its
                            related entities or businesses’, personal information may be one of the transferred business
                            assets.</p>
                        <p><strong>Retaining personal information.</strong> At such time as personal information is no
                            longer required for the purposes stated in this Privacy Statement or other statutory
                            requirements, such personal information will be made anonymous or destroyed.
                        </p>
                        <p><strong>Protecting personal information.</strong> To ensure the security of our customers’
                            personal information we have designated a Privacy Officer to be responsible for the care and
                            control of personal information.
                        </p>
                        <p> Although we take great care in protecting the security of personal information stored in our
                            databases, information transmitted over the Internet can be intercepted by unauthorized
                            parties. Maduxx Inc. does not assume any liability for any unauthorized use or interception
                            of personal information over the internet by third parties. In addition, we do not assume
                            liability for any unauthorized use of personal information collected by third parties with
                            websites that are the current or future subject of a link from our website.
                        </p>
                        <p><strong>Contact Us.</strong> Should you have any questions or comments about our privacy
                            policy and/or practices, we invite you to contact us in one of the following ways:
                        </p>
                        <div><a href="tel:9056603000" class="text-decoration-none text-inherit"><strong>By
                            phone:</strong> (905) 660-3000</a>
                        </div>
                        <div><strong>By mail:</strong> 8500 Jane Street, Vaughan, Ontario L4K 5N9 | Attention: Johnny
                            Maalouf
                        </div>
                        <p><a href="mailto:info@maduxx.com" class="text-decoration-none text-inherit"><strong>By
                            email:</strong> info@maduxx.com</a>
                        </p>
                        <p>
                            This Privacy Policy may be amended from time to time and without notice to you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PrivacyInner;