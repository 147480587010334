import React, {useEffect} from 'react'
import WOW from "wowjs";

function AboutInner() {
    useEffect(() => {
        new WOW.WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: true,
            loop: Infinity,
        }).init();

    }, [])
    return (
        <section className="inner-about-area fix">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
                        <div className="inner-about-img">
                            <img src="assets/img/images/inner_about_img01.png" className="wow fadeInRight"
                                 data-wow-delay=".3s" alt=""/>
                            <img src="assets/img/images/inner_about_img02.png" className="wow fadeInLeft"
                                 data-wow-delay=".6s" alt=""/>
                            <img src="assets/img/images/inner_about_img03.png" className="wow fadeInUp"
                                 data-wow-delay=".6s" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="section-title title-style-three mb-25">
                            <h2>MADUXX <span>GAMES</span></h2>
                        </div>
                        <div className="inner-about-content">
                            <p>Originating from Maduxx.com, we've spent years mastering the art of creating stunning
                                visualizations, animations, and interactive experiences. This expertise is our backbone
                                as we leap into the world of mini and casual gaming. We believe in the power of play.
                                Our mission is to create engaging, captivating, and fun games that resonate with all
                                ages. We're here to make games that are not just played but loved and remembered. Our
                                team is a blend of experienced designers, animators, and developers from Maduxx,
                                alongside fresh talent passionate about gaming. We are dreamers, thinkers, and doers,
                                united by our love for creating games that enchant and entertain.
                            </p>
                            <p>While we're new to game development, our promise is to bring the same level of dedication, quality, and innovation that Maduxx is known for. We're on a journey of exploration, learning, and growth, and we invite you to join us in this exciting new chapter. We're more than just a game company; we're a community of creative minds driven to push the boundaries of casual gaming. Join us on this adventure as we bring our vision of fun, interactive, and imaginative games to life.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-about-shape"><img src="assets/img/images/medale_shape.png" alt=""/></div>
        </section>
    )
}

export default AboutInner