import {BrowserRouter as Router, Switch} from 'react-router-dom';
import HomeTwo from './pages/homes/HomeTwo';
import HomeOne from './pages/homes/HomeOne';
import HomeThree from './pages/homes/HomeThree';
import Shop from './pages/store/Shop';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';
import Overview from './pages/overview/Overview';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Upcoming from './pages/aboutpage/upcoming/Upcoming';
import GameSingle from './pages/aboutpage/singlegame/GameSingle';
import Blogs from './pages/blogs/Blogs';
import BlogDetails from './pages/blogdetails/BlogDetails'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import HomeFour from './pages/homes/HomeFour';
import HomeFive from './pages/homes/HomeFive';
import HomeSix from './pages/homes/HomeSix';
import HomeSeven from './pages/homes/HomeSeven';
import BalloonBashPage from "./pages/aboutpage/singlegame/games/BalloonBashPage";
import RealityRunnerPage from "./pages/aboutpage/singlegame/games/RealityRunnerPage";
import FallingBlocksPage from "./pages/aboutpage/singlegame/games/FallingBlocksPage";
import MetalJacketPage from "./pages/aboutpage/singlegame/games/MetalJacketPage";
import FrenzyPage from "./pages/aboutpage/singlegame/games/FrenzyPage";
import Privacy from "./pages/privacy/Privacy";

// import Loader from "react-js-loader";


function App() {
    //  const [loading,setLoading] = useState(false);

    //  useEffect(()=>{
    //   setLoading(true)
    //   setTimeout(()=>{
    //     setLoading(false)
    //   },8000)
    //  },[])
    //  {
    //   loading ?(
    //     <MoonLoader color={color} loading={loading} css={override} size={150} />
    //   )
    // }

    const gamePaths = [
        '/balloon-bash',
        '/52-frenzy',
        '/metal-jacket',
        '/falling-blocks',
        '/reality-runner'
    ];

    return (
        <div className="App">
            <Router>
                <Switch>
                    <ScrollToTopRoute exact={true} path='/'>
                        <HomeTwo/>
                    </ScrollToTopRoute>

                    <ScrollToTopRoute exact={true} path='/contact'>
                        <Contact/>
                    </ScrollToTopRoute>

                    <ScrollToTopRoute exact={true} path='/about-us'>
                        <AboutUs/>
                    </ScrollToTopRoute>

                    <ScrollToTopRoute exact={true} path='/upcoming-games'>
                        <Upcoming/>
                    </ScrollToTopRoute>

                    <ScrollToTopRoute exact={true} path='/privacy-policy'>
                        <Privacy/>
                    </ScrollToTopRoute>

                    {gamePaths.map(path => (
                        <ScrollToTopRoute exact={true} path={path}>
                            <GameSingle/>
                        </ScrollToTopRoute>
                    ))}

                    {/*<ScrollToTopRoute exact={true} path='/blogs'>*/}
                    {/*    <Blogs/>*/}
                    {/*</ScrollToTopRoute>*/}
                    {/*<ScrollToTopRoute exact={true} path='/blog-details'>*/}
                    {/*    <BlogDetails/>*/}
                    {/*</ScrollToTopRoute>*/}
                    {/*<ScrollToTopRoute exact={true} path='/shop'>*/}
                    {/*    <Shop/>*/}
                    {/*</ScrollToTopRoute>*/}
                    {/*<ScrollToTopRoute exact={true} path='/community'>*/}
                    {/*    <Community/>*/}
                    {/*</ScrollToTopRoute>*/}
                    {/*<ScrollToTopRoute exact={true} path='/overview'>*/}
                    {/*    <Overview/>*/}
                    {/*</ScrollToTopRoute>*/}

                </Switch>
            </Router>
        </div>
    );
}

export default App;
