import react from 'react';
import Footer from "../../components/footer/Footer";
import HeaderTwo from "../../components/header/HeaderTwo";
import PrivacyInner from "./PrivacyInner";
import Breadcrumb from "./Breadcrumb";

function Privacy() {
    return (
        <>
            <HeaderTwo/>
            <main>
                <Breadcrumb/>
                <PrivacyInner/>
            </main>
            <Footer/>
        </>
    )
}


export default Privacy;