import React from 'react'

function FrenzyPage() {
    return (
        <section className="game-single-area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="game-single-content">
                            <div className="upcoming-game-head">
                                <div className="uc-game-head-title">
                                    <h4>52 Frenzy</h4>
                                </div>
                            </div>
                            <p>Get ready for the ultimate test of speed and concentration in 52 Frenzy, an exhilarating
                                card game that's all about quick reflexes and sharp eyes! As the game begins, 52 cards
                                are scattered randomly on your screen. Your mission? Click as fast as you can with your
                                mouse to pick up each card. But it's not just about speed - strategy plays a key role in
                                choosing your next card to maximize your score. The quicker you clear the deck, the
                                higher your score soars. Compete against players from around the world and climb the
                                ranks on the global leaderboard, where speedsters are immortalized. Whether you're a
                                card game aficionado or just looking for a fast-paced challenge, 52 Frenzy promises
                                non-stop excitement and a race against the clock that will keep you coming back for
                                more!</p>
                            <div className="game-single-img">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <img src="assets/games/frenzy/large_image01.jpg" alt=""/>
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="assets/games/frenzy/large_image02.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="game-single-title mt-60 mb-30">
                                <h4>Specifications</h4>
                            </div>
                            <div className="game-single-info mb-65">
                                <ul>
                                    <li><span>Category:</span>Casual</li>
                                    <li><span>Platforms:</span>PC, iOS, Android</li>
                                </ul>
                            </div>
                            <div className="game-single-gallery">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <img src="assets/games/frenzy/gallery_image_01.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="game-single-shape"><img src="assets/img/images/game_section_shape.png"
                                                                    alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FrenzyPage